import { ButtonProps, Button, Center } from "@chakra-ui/react";
import { FC } from "react";
import ShiIcon from "src/atoms/ShiIcon";

const CarouselArrow: FC<ButtonProps & { light?: boolean, useAlternateDesign?: boolean, shouldHideIfDisabled?: boolean }> = ({
  light,
  useAlternateDesign = false,
  shouldHideIfDisabled = false,
  ...props
}) => {

  if (useAlternateDesign) {
    const disabledProps = shouldHideIfDisabled ? {
      display: "none"
    } : {
      opacity: 0.2
    };

    return (
      <Button
        {...props}
        variant="ghost"
        padding="0"
        _disabled={disabledProps}
      >
        <Center
          bgColor="white"
          fontSize="14px"
          lineHeight="18px"
          minHeight="50px"
          maxHeight="50px"
          minWidth="50px"
          maxWidth="50px"
          border="1px"
          borderRadius="25px"
        >
          <ShiIcon
            name="chevron"
            // transform={{ base: "scale(1)", md: "scale(1.5)"}}
            transform={"rotate(-90deg)"}
          />
        </Center>
      </Button>
    );
  }

  return (
    <Button
      bg={light ? "greyLight-3" : "bg-primary-active"}
      color={light ? "text-primary" : "white"}
      _hover={
        props.disabled
          ? {}
          : {
            bg: light ? "bg-primary-active" : "grey-1",
            color: light ? "white" : "white"
          }
      }
      _disabled={{
        bg: "greyLight-3",
        color: "text-primary",
        opacity: "0.6",
        cursor: "default",
      }}
      paddingY="11px"
      borderRadius="full"
      {...props}
    >
      →
    </Button>
  );
};

export default CarouselArrow;
