import {
  Box,
  SimpleGrid,
  useBreakpointValue,
  HStack,
  BoxProps,
  SimpleGridProps,
  Flex
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { cloneElement, FC, useState } from "react";
import CarouselArrow from "./components/CarouselArrow";
import { motionVariantsDynamic } from "./utils";

const Carousel: FC<
  BoxProps & {
    items: any[];
    breakPointValue?: { base: number; md: number; lg: number };
    lightColor?: boolean,
    carouselWrapperProps?: SimpleGridProps
    indicators?: 'arrows' | 'dots' | 'floating-arrows'
  }
> = ({
  items,
  breakPointValue,
  lightColor,
  carouselWrapperProps,
  indicators = 'arrows',
  ...rest
}) => {
  const [[carouselIndex, direction], setCarouselIndex] = useState([0, 0]);

  const carouselBreakpointValue = breakPointValue ?? { base: 1, md: 2, lg: 3 };

  const numberOfItems = useBreakpointValue(carouselBreakpointValue) ?? 1;
  const pagesNumber = Math.ceil(items.length / numberOfItems);
  const shouldShowArrows = Boolean(numberOfItems && pagesNumber > 1);

  return (
    <Box {...rest}>
      <AnimatePresence custom={direction} exitBeforeEnter initial={false}>
        <motion.div
          custom={direction}
          initial="before"
          animate="in"
          exit="after"
          variants={motionVariantsDynamic('10px')}
          transition={{ ease: "easeOut", duration: 0.2 }}
          key={carouselIndex}
        >
          <SimpleGrid
            templateColumns={{
              base: `repeat(${carouselBreakpointValue.base}, 1fr)`,
              md: `repeat(${carouselBreakpointValue.md}, 1fr)`,
              lg: `repeat(${carouselBreakpointValue.lg}, 1fr)`,
            }}
            spacing="space-32"
            paddingTop="space-64"
            {...carouselWrapperProps}
          >
            {Array(carouselBreakpointValue.lg)
              .fill("")
              .map((_, i) => {
                const item = items[carouselIndex + i];

                return (
                  item &&
                  cloneElement(item, {
                    display: {
                      base:
                        i <= carouselBreakpointValue.base - 1
                          ? "block"
                          : "none",
                      md:
                        i <= carouselBreakpointValue.md - 1 ? "block" : "none",
                      lg:
                        i <= carouselBreakpointValue.lg - 1 ? "block" : "none",
                    },
                  })
                );
              })}

            {indicators === 'floating-arrows' && shouldShowArrows ? ( 
              <Flex alignItems="stretch" position="absolute" top="256px" w="full"
                // border="1px solid red"
              >
                  <CarouselArrow
                    aria-label="prev"
                    light={lightColor}
                    disabled={carouselIndex <= 0}
                    useAlternateDesign={true}
                    shouldHideIfDisabled={true}
                    onClick={() =>
                      setCarouselIndex([
                        Math.max(carouselIndex - numberOfItems, 0),
                        -1,
                      ])
                    }
                    position="absolute"
                    left="-24px"
                    transform="rotate(180deg)"
                  />
                  <CarouselArrow
                    disabled={carouselIndex / numberOfItems >= pagesNumber - 1}
                    aria-label="next"
                    useAlternateDesign={true}
                    shouldHideIfDisabled={true}
                    position="absolute"
                    right="-24px"
                    onClick={() =>
                      setCarouselIndex([carouselIndex + numberOfItems, 1])
                    }
                  />
              </Flex>
            ) : null}
          </SimpleGrid>
        </motion.div>
      </AnimatePresence>

      {indicators === 'arrows' && shouldShowArrows ? ( 
        <HStack mt="space-40" spacing="space-16" justify="center">
          {
            <CarouselArrow
              aria-label="prev"
              light={lightColor}
              disabled={carouselIndex <= 0}
              onClick={() =>
                setCarouselIndex([
                  Math.max(carouselIndex - numberOfItems, 0),
                  -1,
                ])
              }
              transform="rotate(180deg)"
            />
          }
          {
            <CarouselArrow
              disabled={carouselIndex / numberOfItems >= pagesNumber - 1}
              aria-label="next"
              light={lightColor}
              onClick={() =>
                setCarouselIndex([carouselIndex + numberOfItems, 1])
              }
            />
          }
        </HStack>
      ) : null}

      {indicators === 'dots' ? (
        <HStack spacing="space-8" mt="space-16">
          {Array(pagesNumber).fill("").map((_, i) => {
            const isActive = carouselIndex === i;
  
            return (
              <Box
                key={i}
                w={isActive ? "13px" : "6.5px"}
                h="6.5px"
                borderRadius="6.5px"
                bg={isActive ? "black" : "greyLight-3"}
                cursor="pointer"
                onClick={() => setCarouselIndex([i, i > carouselIndex ? 1 : -1])}
                sx={{
                  transition: "width .2s ease",
                }}
              />
            );
          })}
        </HStack>
      ) : null}
    </Box>
  );
};

export default Carousel;
